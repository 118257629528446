<script lang="ts" setup></script>

<template>
  <div class="flex">
    <MainNavigation />
    <div class="h-screen w-[25rem] border-r border-neutral-4 flex flex-col relative bg-neutral-1">
      <slot />
    </div>
    <NuxtPage />
  </div>
</template>
